import React, { FC } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { Text } from 'components';
import { tablet, useQuery } from 'styles/breakpoints';
import { AppState } from 'state/types';
import { getLocalisedProduct } from 'utils/localization';
import { Product } from 'types/product';

interface OrderSummaryProps {
  title: string;
}

const Container = styled.div`
  max-width: 71.25rem;
  width: 100%;
  margin-top: 6rem;
  margin-bottom: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
  @media ${tablet} {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
`;

const Heading = styled(Text)`
  margin-bottom: 3rem;

  @media ${tablet} {
    text-align: center;
  }
`;

const SummaryContainer = styled.div`
  width: 100%;
  max-width: 40rem;
  display: flex;
  flex-direction: column;
`;

const OrderItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 2rem;
  width: 100%;
`;

const TotalItemContainer = styled(OrderItemContainer)`
  border-top: 1px solid ${({ theme }) => theme.colors.light100};
  padding: 2rem 0 0 0;
`;

const OrderSummary: FC<OrderSummaryProps> = ({ title }) => {
  const { isTablet } = useQuery();
  const { selected_plan, upsell_products } = useSelector(
    (state: AppState) => state.user,
  );

  const selectedProduct = getLocalisedProduct(selected_plan);
  const localisedUpsellProducts = upsell_products.map(p =>
    getLocalisedProduct(p),
  );

  const renderUpsellProducts = (upsell_product: Product, index: number) => {
    const upsellProduct = getLocalisedProduct(upsell_product);

    return (
      <OrderItemContainer key={index}>
        <Text type={isTablet ? 'body' : 'h3400'} color="dark80">
          {upsellProduct.name}
        </Text>
        <Text type={isTablet ? 'body' : 'h3400'} color="dark80">
          {upsellProduct.currency}
          {upsellProduct.finalPrice}
        </Text>
      </OrderItemContainer>
    );
  };

  const upsellsPrice = localisedUpsellProducts.reduce(
    (sum, p) => sum + parseFloat(p?.finalPrice || '0'),
    0,
  );

  const productVisiblePrice = selectedProduct?.hasTrial
    ? selectedProduct?.discountedTrialPrice
    : selectedProduct?.finalPrice;

  const totalPrice = (
    parseFloat(Number(productVisiblePrice)) + parseFloat(upsellsPrice)
  ).toFixed(2);

  return (
    <Container>
      <Heading type={isTablet ? 'h3' : 'count'}>{title}</Heading>
      <SummaryContainer>
        <OrderItemContainer>
          <Text type={isTablet ? 'body' : 'h3400'} color="dark80">
            {selectedProduct.name.replace('Effecto ', '')}
          </Text>
          <Text type={isTablet ? 'body' : 'h3400'} color="dark80">
            {selectedProduct.currency}
            {Number(productVisiblePrice)}
          </Text>
        </OrderItemContainer>
        {upsell_products.map(renderUpsellProducts)}
        <TotalItemContainer>
          <Text type={isTablet ? 'tabActive' : 'h3'} color="dark80">
            Total
          </Text>
          <Text type={isTablet ? 'tabActive' : 'h3'} color="dark80">
            {selectedProduct.currency}
            {totalPrice}
          </Text>
        </TotalItemContainer>
      </SummaryContainer>
    </Container>
  );
};

export default OrderSummary;
